exports.components = {
  "component---src-pages-10-colorful-years-js": () => import("./../../../src/pages/10-colorful-years.js" /* webpackChunkName: "component---src-pages-10-colorful-years-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-accessibility-js": () => import("./../../../src/pages/accessibility.js" /* webpackChunkName: "component---src-pages-accessibility-js" */),
  "component---src-pages-all-curl-js": () => import("./../../../src/pages/all-curl.js" /* webpackChunkName: "component---src-pages-all-curl-js" */),
  "component---src-pages-applications-js": () => import("./../../../src/pages/applications.js" /* webpackChunkName: "component---src-pages-applications-js" */),
  "component---src-pages-be-a-hero-today-js": () => import("./../../../src/pages/be-a-hero-today.js" /* webpackChunkName: "component---src-pages-be-a-hero-today-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blow-dry-spray-js": () => import("./../../../src/pages/blow-dry-spray.js" /* webpackChunkName: "component---src-pages-blow-dry-spray-js" */),
  "component---src-pages-care-quiz-js": () => import("./../../../src/pages/care-quiz.js" /* webpackChunkName: "component---src-pages-care-quiz-js" */),
  "component---src-pages-carry-us-js": () => import("./../../../src/pages/carry-us.js" /* webpackChunkName: "component---src-pages-carry-us-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-collection-all-js": () => import("./../../../src/pages/collection/all.js" /* webpackChunkName: "component---src-pages-collection-all-js" */),
  "component---src-pages-color-list-js": () => import("./../../../src/pages/color-list.js" /* webpackChunkName: "component---src-pages-color-list-js" */),
  "component---src-pages-data-protection-js": () => import("./../../../src/pages/data-protection.js" /* webpackChunkName: "component---src-pages-data-protection-js" */),
  "component---src-pages-diamond-rewards-terms-js": () => import("./../../../src/pages/diamond-rewards-terms.js" /* webpackChunkName: "component---src-pages-diamond-rewards-terms-js" */),
  "component---src-pages-education-js": () => import("./../../../src/pages/education.js" /* webpackChunkName: "component---src-pages-education-js" */),
  "component---src-pages-educator-application-js": () => import("./../../../src/pages/educator-application.js" /* webpackChunkName: "component---src-pages-educator-application-js" */),
  "component---src-pages-event-list-js": () => import("./../../../src/pages/event-list.js" /* webpackChunkName: "component---src-pages-event-list-js" */),
  "component---src-pages-good-hair-magic-js": () => import("./../../../src/pages/good-hair-magic.js" /* webpackChunkName: "component---src-pages-good-hair-magic-js" */),
  "component---src-pages-good-hair-magic-pro-js": () => import("./../../../src/pages/good-hair-magic-pro.js" /* webpackChunkName: "component---src-pages-good-hair-magic-pro-js" */),
  "component---src-pages-hairspray-quiz-js": () => import("./../../../src/pages/hairspray-quiz.js" /* webpackChunkName: "component---src-pages-hairspray-quiz-js" */),
  "component---src-pages-in-salon-class-request-js": () => import("./../../../src/pages/in-salon-class-request.js" /* webpackChunkName: "component---src-pages-in-salon-class-request-js" */),
  "component---src-pages-in-salon-request-submitted-js": () => import("./../../../src/pages/in-salon-request-submitted.js" /* webpackChunkName: "component---src-pages-in-salon-request-submitted-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-the-team-js": () => import("./../../../src/pages/join-the-team.js" /* webpackChunkName: "component---src-pages-join-the-team-js" */),
  "component---src-pages-kenra-care-js": () => import("./../../../src/pages/kenra-care.js" /* webpackChunkName: "component---src-pages-kenra-care-js" */),
  "component---src-pages-kenra-color-creatives-js": () => import("./../../../src/pages/kenra-color/creatives.js" /* webpackChunkName: "component---src-pages-kenra-color-creatives-js" */),
  "component---src-pages-kenra-color-index-js": () => import("./../../../src/pages/kenra-color/index.js" /* webpackChunkName: "component---src-pages-kenra-color-index-js" */),
  "component---src-pages-kenra-js": () => import("./../../../src/pages/kenra.js" /* webpackChunkName: "component---src-pages-kenra-js" */),
  "component---src-pages-kenra-platinum-js": () => import("./../../../src/pages/kenra-platinum.js" /* webpackChunkName: "component---src-pages-kenra-platinum-js" */),
  "component---src-pages-kenra-ready-js": () => import("./../../../src/pages/kenra-ready.js" /* webpackChunkName: "component---src-pages-kenra-ready-js" */),
  "component---src-pages-luxe-collection-js": () => import("./../../../src/pages/luxe-collection.js" /* webpackChunkName: "component---src-pages-luxe-collection-js" */),
  "component---src-pages-magic-mousse-js": () => import("./../../../src/pages/magic-mousse.js" /* webpackChunkName: "component---src-pages-magic-mousse-js" */),
  "component---src-pages-not-supported-js": () => import("./../../../src/pages/not-supported.js" /* webpackChunkName: "component---src-pages-not-supported-js" */),
  "component---src-pages-quiz-js": () => import("./../../../src/pages/quiz.js" /* webpackChunkName: "component---src-pages-quiz-js" */),
  "component---src-pages-rapid-hydration-masks-js": () => import("./../../../src/pages/rapid-hydration-masks.js" /* webpackChunkName: "component---src-pages-rapid-hydration-masks-js" */),
  "component---src-pages-rapunzel-project-js": () => import("./../../../src/pages/rapunzel-project.js" /* webpackChunkName: "component---src-pages-rapunzel-project-js" */),
  "component---src-pages-recent-launches-js": () => import("./../../../src/pages/recent-launches.js" /* webpackChunkName: "component---src-pages-recent-launches-js" */),
  "component---src-pages-salon-locator-js": () => import("./../../../src/pages/salon-locator.js" /* webpackChunkName: "component---src-pages-salon-locator-js" */),
  "component---src-pages-simply-add-water-js": () => import("./../../../src/pages/simply-add-water.js" /* webpackChunkName: "component---src-pages-simply-add-water-js" */),
  "component---src-pages-simply-blonde-bk-js": () => import("./../../../src/pages/simply-blonde-bk.js" /* webpackChunkName: "component---src-pages-simply-blonde-bk-js" */),
  "component---src-pages-simply-blonde-js": () => import("./../../../src/pages/simply-blonde.js" /* webpackChunkName: "component---src-pages-simply-blonde-js" */),
  "component---src-pages-spray-slay-repeat-js": () => import("./../../../src/pages/spray-slay-repeat.js" /* webpackChunkName: "component---src-pages-spray-slay-repeat-js" */),
  "component---src-pages-submitted-js": () => import("./../../../src/pages/submitted.js" /* webpackChunkName: "component---src-pages-submitted-js" */),
  "component---src-pages-texture-guide-js": () => import("./../../../src/pages/texture-guide.js" /* webpackChunkName: "component---src-pages-texture-guide-js" */),
  "component---src-pages-tools-js": () => import("./../../../src/pages/tools.js" /* webpackChunkName: "component---src-pages-tools-js" */),
  "component---src-pages-triple-repair-js": () => import("./../../../src/pages/triple-repair.js" /* webpackChunkName: "component---src-pages-triple-repair-js" */),
  "component---src-pages-video-js": () => import("./../../../src/pages/video.js" /* webpackChunkName: "component---src-pages-video-js" */),
  "component---src-pages-volume-mist-2-js": () => import("./../../../src/pages/volume-mist-2.js" /* webpackChunkName: "component---src-pages-volume-mist-2-js" */),
  "component---src-pages-volume-spray-25-throwback-js": () => import("./../../../src/pages/volume-spray-25-throwback.js" /* webpackChunkName: "component---src-pages-volume-spray-25-throwback-js" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/blogPost.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-collection-jsx": () => import("./../../../src/templates/collection.jsx" /* webpackChunkName: "component---src-templates-collection-jsx" */),
  "component---src-templates-custom-page-jsx": () => import("./../../../src/templates/customPage.jsx" /* webpackChunkName: "component---src-templates-custom-page-jsx" */),
  "component---src-templates-event-detail-jsx": () => import("./../../../src/templates/eventDetail.jsx" /* webpackChunkName: "component---src-templates-event-detail-jsx" */),
  "component---src-templates-product-jsx": () => import("./../../../src/templates/product.jsx" /* webpackChunkName: "component---src-templates-product-jsx" */),
  "component---src-templates-video-jsx": () => import("./../../../src/templates/video.jsx" /* webpackChunkName: "component---src-templates-video-jsx" */)
}

